.grid-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: center;
  }
  
  .item {
    background-color: white;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }