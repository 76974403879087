.footer {
    background-color: silver;
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    height: 100px; /* Set the footer height to be twice the header height */
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
  }
  
  .footer-column a {
    color: black;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }