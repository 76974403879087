.header {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    height: 5vh; 
  }
  

  .nav {
    flex:1;
  }
  
  .nav button {
    background: none;
    border: none;
    color: white;
    margin: 0 1rem;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .basket {
    font-size: 1.5rem;
    color: white;
    width: 10rem;
  }